"use client"
// components/Textarea.tsx
import React, { useEffect, useState } from 'react';
import '../App.css'
import ModalDialog from './Modal/Modal';
import Editor, { ContentEditableEvent, createButton } from 'react-simple-wysiwyg';

// const Poznamky: React.FC = () => {
function Poznamky(props: { handleSetApp: (app: string) => void }) {

    const isJuri = window.location.hostname == 'juri.apwb.sk';

    const [ipad, setIpad] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [saved, setSaved] = useState<boolean>(true);
    const [nadpis, setNadpis] = useState<string>('');
    const [nazovSaveAs, setNazovSaveAs] = useState<string>('');
    const [nazov4load, setNazov4load] = useState<string>(isJuri ? 'juri.txt' : 'saved_data.txt');
    const [folderContent, setFolderContent] = useState<string[]>([]);

    const [numbrekod, setNumberkod] = useState<string>('');

    useEffect(() => {
        const getContent = (async () => {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'pragma': 'no-cache', 'cache-control': 'no-cache' },
                    body: JSON.stringify({ file: nazov4load })
                };
                const response = await fetch('api/get-content.php', requestOptions).then(response => response.json());
                setContent(response.content);
                setFolderContent(response.folder);
            } catch (error) {
                setNadpis('Nepodarilo sa nahrat obsah.')
            }
        })
        getContent()
    }, [nazov4load])

    useEffect(() => {
        const fileUrl = 'https://api.apwb.sk/api/ipad.dr';
        fetch(fileUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();
            })
            .then((text) => {
                setIpad(text);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [])

    useEffect(() => {
        if (nazov4load == 'CISLA.txt' && numbrekod != '2364') {
            setNazov4load('saved_data.txt')
        }
    }, [numbrekod])

    useEffect(() => {
        const saveData = (async () => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ data: content, file: nazov4load }),
            };
            try {
                const response = await fetch('api/save-content.php', requestOptions).then(response => response.json())
                setFolderContent(response.folder);
            } catch (error) {
                setNadpis('Nepodarilo sa uložiť obsah.')
            }
        })

        if (!saved) {
            saveData()
            setSaved(true)
        }
    }, [content, saved])

    const handleChange = (e: ContentEditableEvent) => {
        setSaved(false)
        setContent(e.target.value);
    };

    const handleKodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNumberkod(e.target.value);
    };

    const BtnAlignCenter = createButton('Align center', '≡', 'justifyCenter');

    const splitIntoLines = (htmlString: string) => {
        // Nahradíme HTML značky na oddelenie riadkov
        const plainText = htmlString
            .replace(/<br\s*\/?>/g, "\n") // Nahradíme <br> za nový riadok
            .replace(/<\/div>/g, "\n") // Nahradíme </div> za nový riadok
            .replace(/<[^>]+>/g, "") // Odstránime všetky ostatné HTML značky
            .trim(); // Odstránime nadbytočné medzery

        // Rozdelíme text podľa riadkov
        return plainText.split("\n").filter((line) => line.trim() !== "");
    };
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return (
        <div >
            {!isJuri &&
                <div style={{ width: '100%', textAlign: 'center', paddingTop: 5, paddingBottom: 5 }}>
                    <div style={saved ? {} : { color: 'red' }}>Poznámečky</div>
                    <div style={{ color: 'orange', fontSize: 10 }}>{nadpis}</div>
                    {/* <div style={{ fontSize: 10 }}>{nazov4load}</div> */}
                    <div>
                        <select onChange={(e) => setNazov4load(e.target.value)} style={{ height: 20 }}>
                            {folderContent.filter(f => numbrekod == '2364' || f != 'CISLA.txt').map(filename => {
                                return <option
                                    value={filename}
                                    selected={filename == nazov4load}
                                >
                                    {filename.replace('.txt', '')}
                                </option>
                            })}
                        </select>
                        <input type="password" onChange={handleKodChange} value={numbrekod} />
                    </div>

                </div>
            }
            <Editor
                containerProps={{ style: { textAlign: 'left', backgroundColor: '#f8f8f8', padding: 10, resize: 'vertical' } }}
                value={content}
                onChange={handleChange}
            />
            {/* {CustomEditor(content, handleChange)} */}
            {/* <textarea className='pozn_textarea' value={content} onChange={handleChange} rows={10} cols={50} /> */}
            {!isJuri &&
                <>
                    <ModalDialog
                        header='Ukladanie do nového súboru'
                        buttons={['ok', 'cancel']}
                        onConfirm={() => {
                            const saveData = (async () => {
                                const requestOptions = {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify({ data: content, file: nazovSaveAs + '.txt' }),
                                };
                                try {
                                    await fetch('api/save-content.php', requestOptions).then(response => response.json())
                                    setNadpis('Uložené s názvom ' + nazovSaveAs + '.txt.')
                                } catch (error) {
                                    setNadpis('Nepodarilo sa uložiť obsah..')
                                }
                            })
                            saveData()
                        }}
                    >
                        <div><input type='text' placeholder='nazov' onChange={(e) => setNazovSaveAs(e.target.value)} /></div>
                    </ModalDialog>
                    <div style={{ paddingLeft: 15, color: '#555' }}>{ipad}</div>
                    <div>
                        {splitIntoLines(content).map((text, index) => {
                            const parts = text.split(urlRegex);
                            return <div key={index}>
                                {parts.map((part, index1) => {
                                    if (urlRegex.test(part)) {
                                        return <p key={index}>{index + 1}. <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a></p>
                                    }
                                    return <></>
                                })}
                            </div>
                        })}
                    </div>
                    <div className='selector'>
                        <button onClick={() => props.handleSetApp('kalendar')}>Kalendár</button>
                    </div>
                </>
            }
        </div>
    );
};

export default Poznamky;
